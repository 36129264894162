import React from "react"
import { graphql } from "gatsby"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Skeleton from "@material-ui/lab/Skeleton"

import ToolLayout from "../../../components/tool_layout"
import CopyToClipboardButton from "../../../components/widgets/copy_to_clipboard"

export const query = graphql`
  query MyNetIPQuery {
    allToolsJson(filter: { path: { eq: "net/my-ip" } }) {
      edges {
        node {
          path
          title
          description
          links {
            mdn
            github
          }
        }
      }
    }
  }
`

class MyNetIpState {
  ip: string | null
  error: Error | null
}

class MyNetIpPage extends React.Component<{ data: any }> {
  state: MyNetIpState

  constructor(props) {
    super(props)
    this.state = new MyNetIpState()
  }

  refresh = () => {
    this.setState({
      ip: null,
      error: null,
    })

    fetch(this.getApi())
      .then(response => response.text())
      .then(data => this.setState({ ip: data }))
      .catch(err => this.setState({ error: err }))
  }

  getApi = () => {
    if (process.env.NODE_ENV === "production") {
      return "https://api.twubr.com/api/ip"
    }

    return "/api/ip"
  }

  componentDidMount() {
    this.refresh()
  }

  contentWidget = () => {
    if (!this.state.ip && !this.state.error) {
      return (
        <>
          {Array.from(Array(3).keys()).map(index => (
            <Skeleton animation="wave" key={index} />
          ))}
        </>
      )
    }

    if (this.state.error) {
      return (
        <>
          <Box
            width={1}
            display="flex"
            justifyContent="center"
            fontSize="h2.fontSize"
          >
            {this.state.error.message}
          </Box>
          <Box width={1} mt={4} display="flex" justifyContent="center">
            <Button
              color="secondary"
              variant="contained"
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </Box>
        </>
      )
    }

    return (
      <>
        <Box
          width={1}
          display="flex"
          justifyContent="center"
          fontSize="h2.fontSize"
        >
          {this.state.ip}
        </Box>
        <Box width={1} mt={4} display="flex" justifyContent="center">
          <CopyToClipboardButton value={this.state.ip} />
        </Box>
      </>
    )
  }

  render() {
    return (
      <ToolLayout data={this.props.data}>
        <Paper>
          <Box p={4}>{this.contentWidget()}</Box>
        </Paper>
      </ToolLayout>
    )
  }
}

export default MyNetIpPage
